// src/App.tsx
import { BrowserRouter, Routes, Route } from "react-router-dom";
import './index.css';
import Home from "./pages/Home";
import PropertyDetails from "./pages/PropertyDetails";
import ApprovalAlert from "./components/Approval";

export default function App() {
  const isApprovalEnv = process.env.REACT_APP_ENV === "approval";

  return (
    <div style={{ paddingBottom: isApprovalEnv ? '30px' : '0' }}>
      <BrowserRouter>
        {isApprovalEnv && <ApprovalAlert/>}
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/detalhes-do-imovel/:secureId" element={<PropertyDetails />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}