import { useState, useEffect } from 'react';
import { Box, Typography, Button, IconButton, Container } from '@mui/material';
import api from "../../../services/api";
import { useNavigate } from "react-router-dom";
import ChevronLeft from '../../../assets/icons/ChevronLeft';
import ChevronRight from '../../../assets/icons/ChevronRight';
import './style.css';
import truncateText from '../../../utils/truncateText';
import { Contact } from '../Contact';

interface Property {
  id: string;
  image: string;
  title: string;
  description: string;
  price: string;
}

interface ComponentProps {
  title: string;
  subTitle: string;
  id: string;
}

export default function Carousel({ title, subTitle, id }: ComponentProps) {
  const [properties, setProperties] = useState<Property[]>([]);
  const [currentIndex, setCurrentIndex] = useState<number>(0);
  const [openContactDialog, setOpenContactDialog] = useState<boolean>(false);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchImages = async () => {
      try {
        const response = await api.get(`list-sections/${id}`);
        const allProperties: Property[] = response.data.map((item: any) => ({
          id: item.secure_id,
          image: item.images[0]?.path || "/placeholder.svg?height=800&width=600",
          title: item.friendly_title,
          description: `${item.property_type} - ${item.city}, ${item.state}. ${item.area} m², ${item.bedrooms} quartos, ${item.suites} suítes, ${item.parking_spots} vagas`,
          price: parseFloat(item.sale_price).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }),
        }));
        setProperties(allProperties);
      } catch (error) {
        console.error("Error fetching properties:", error);
      }
    };

    if (id) {
      fetchImages();
    }
  }, [id]);

  useEffect(() => {
    const timer = setInterval(() => {
      handleNext();
    }, 5000);
    return () => clearInterval(timer);
  }, [properties]);

  const handlePrevious = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? properties.length - 1 : prevIndex - 1
    );
  };

  const handleNext = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === properties.length - 1 ? 0 : prevIndex + 1
    );
  };

  const handleImageClick = (id: string) => {
    navigate(`/detalhes-do-imovel/${id}`);
  };

  return (
    <Box className="carousel" sx={{ width: '100%', bgcolor: '#ffffff', py: 4 }}>
      <Container maxWidth="lg" sx={{ mb: 4 }}>
        <Typography className='joinville-text'>{title}</Typography>
      </Container>

      <Container
        style={{ maxWidth: '870px' }}
        sx={{
          position: 'relative',
          borderRadius: 2,
          p: 2,
          aspectRatio: '16 / 9',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between'
        }}
      >
        <Box sx={{ position: 'absolute', top: '-40px', right: '5%', zIndex: 2, display: { xs: 'none', sm: 'flex' }, gap: 4, justifyContent: 'center', width: '35%' }}>
          <IconButton
            onClick={handlePrevious}
            sx={{
              width: '40px',
              display: 'grid',
              placeItems: 'center',
              bgcolor: '#C7FD06',
              color: 'white',
              '&:hover': { bgcolor: '#C7FD06' },
            }}
          >
            <ChevronLeft />
          </IconButton>
          <IconButton
            onClick={handleNext}
            sx={{
              width: '40px',
              display: 'grid',
              placeItems: 'center',
              bgcolor: '#C7FD06',
              color: 'white',
              '&:hover': { bgcolor: '#C7FD06' },
            }}
          >
            <ChevronRight />
          </IconButton>
        </Box>

        <Box
          sx={{
            position: 'relative',
            width: '100%',
            height: '498px',
            overflow: 'hidden',
            display: 'flex',
            justifyContent: 'flex-start',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              transition: 'transform 0.5s ease',
              transform: `translateX(-${currentIndex * (458 + 458)}px)`,
              gap: '458px',
              py: 2
            }}
          >
            {properties.map((property) => (
              <Box
                key={property.id}
                component="img"
                src={property.image}
                alt={property.title}
                onClick={() => handleImageClick(property.id)}
                sx={{
                  width: '458px',
                  objectFit: 'cover',
                  cursor: 'pointer',
                  flexShrink: 0,
                  boxShadow: '4px 4px 7px rgba(0, 0, 0, 0.2)'
                }}
              />
            ))}
          </Box>
        </Box>

        <Box
          className={`overlay`}
          sx={{
            position: 'absolute',
            top: { xs: 'auto', sm: '22%' },
            bottom: { xs: '-100px', sm: 'auto' },
            left: { xs: '50%', sm: 'auto' },
            transform: { xs: 'translateX(-50%)', sm: 'translateX(40px)' },
            right: { xs: 'auto', sm: '5%' },
            width: { xs: '90%', sm: '432px' },
            maxWidth: '75%',
            height: { xs: 'auto', sm: '186px' },
            bgcolor: '#ffffff',
            borderRadius: { xs: '2px', sm: '0 0 53px 0' },
            p: 2,
            boxShadow: '0px 4px 17.5px rgba(0, 0, 0, 0.1)',
            zIndex: 1,
            transition: 'opacity 0.5s ease',
            textAlign: { xs: 'center', sm: 'left' }
          }}
        >
          <Typography variant="h5" gutterBottom style={{ fontWeight: '700' }}>
            {truncateText(properties[currentIndex]?.title || '', 25)}
          </Typography>
          <Typography variant="body1" paragraph>
            {truncateText(properties[currentIndex]?.description || '', 150)}
          </Typography>
          <Button
            variant="contained"
            sx={{ background: 'linear-gradient(90deg, #c7fd06 0.31%, #779704 100.31%)', color: 'black', mt: 2, py: '4px' }}
            onClick={() => setOpenContactDialog(true)}
          >
            Saiba Mais
          </Button>
        </Box>
      </Container>

      <Contact openDialog={openContactDialog} onCloseDialog={() => setOpenContactDialog(false)} />
    </Box>
  );
}