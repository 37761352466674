import React, { useEffect, useState } from 'react';
import { Card, CardContent, Typography, Grid, Box, Container } from '@mui/material';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import PhoneIcon from '@mui/icons-material/Phone';
import api from '../../../services/api';
import SvgContainer from '../../../components/SvgContainer';

const CompanyCard = ({ name, city, phone, address, background }: any) => {
  const googleMapsUrl = `${address}`;
  const phoneUrl = `tel:${phone}`;

  return (
    <Card sx={{
      color: '#fff',
      m: 2,
      border: 1,
      borderColor: '#c7fd06',
      background: `url(${process.env.PUBLIC_URL}/assets/images/banner/${background})`,
      backgroundPositionX:'40%',
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'cover',
      p: 2
    }}>
      <CardContent sx={{
        maxWidth: '350px',
        paddingTop: '40px',
        height: '160px',
        paddingLeft: 0
      }}>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Typography variant="h5" sx={{ fontWeight: 'medium' }}>{name}</Typography>
        </Box>

        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <LocationOnIcon sx={{ color: '#fff', mr: 1 }} />
          <Typography variant="body2" sx={{ mt: 1 }}>
            <a href={googleMapsUrl} target="_blank" rel="noopener noreferrer" style={{ color: '#fff', textDecoration: 'none' }}>
              {city}
            </a>
          </Typography>
        </Box>

        <Box sx={{ display: 'flex', alignItems: 'center', mt: 1 }}>
          <PhoneIcon sx={{ color: '#fff', mr: 1 }} />
          <Typography variant="body2">
            <a href={phoneUrl} style={{ color: '#fff', textDecoration: 'none' }}>
              {phone}
            </a>
          </Typography>
        </Box>
      </CardContent>
    </Card>
  );
};

const CompanyLocations = () => {
  const [offices, setOffices] = useState([]);

  useEffect(() => {
    const fetchOffices = async () => {
      try {
        const response = await api.get('list-offices');
        setOffices(response.data);
      } catch (error) {
        console.error('Failed to fetch offices', error);
      }
    };

    fetchOffices();
  }, []);

  return (
    <Box sx={{ position: 'relative', width: '100%' }}>
      <Container sx={{ position: 'relative', bgcolor: 'white', color: 'black' }} >
        <Box style={{ zIndex: 2, position: 'relative' }}>
          <CardContent sx={{ textAlign: 'center', borderBottom: 1, borderColor: 'transparent' }}>
            <Typography variant="h4" className='section-title'>
              Nossa Lojas
            </Typography>
            <Typography variant="subtitle1" sx={{ fontFamily: 'Red Hat Display', fontWeight: 700, lineHeight: '58px' }}>
              Conheça nossos locais de atendimento
            </Typography>
          </CardContent>
          <Grid container spacing={2} sx={{ p: 2 }}>
            {offices.map((office: any, index) => (
              <Grid item xs={12} sm={6} key={index}>
                <CompanyCard
                  {...office}
                  background={index === 0 ? 'picarras.jpg' : 'joinville.jpg'}
                />
              </Grid>
            ))}
          </Grid>
        </Box>
      </Container>
      <SvgContainer />
    </Box>
  );
};

export default CompanyLocations;
