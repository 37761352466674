

import React from 'react';

export default function ChevronLeft() {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="14" height="24" viewBox="0 0 14 24" fill="none">
            <path d="M13.4094 0.591025C13.2238 0.40375 13.0029 0.255105 12.7596 0.153666C12.5163 0.0522265 12.2553 0 11.9917 0C11.7281 0 11.4672 0.0522265 11.2238 0.153666C10.9805 0.255105 10.7597 0.40375 10.5741 0.591025L0.590618 10.5814C0.403471 10.7671 0.254929 10.9881 0.153559 11.2316C0.05219 11.4751 0 11.7362 0 12C0 12.2638 0.05219 12.5249 0.153559 12.7684C0.254929 13.0119 0.403471 13.2329 0.590618 13.4186L10.5741 23.409C10.7597 23.5962 10.9805 23.7449 11.2238 23.8463C11.4672 23.9478 11.7281 24 11.9917 24C12.2553 24 12.5163 23.9478 12.7596 23.8463C13.0029 23.7449 13.2238 23.5962 13.4094 23.409C13.5965 23.2232 13.7451 23.0022 13.8464 22.7588C13.9478 22.5153 14 22.2541 14 21.9903C14 21.7266 13.9478 21.4654 13.8464 21.2219C13.7451 20.9785 13.5965 20.7575 13.4094 20.5717L4.82361 12L13.4094 3.42828C13.5965 3.24254 13.7451 3.02155 13.8464 2.77807C13.9478 2.53458 14 2.27342 14 2.00965C14 1.74589 13.9478 1.48473 13.8464 1.24124C13.7451 0.99776 13.5965 0.776772 13.4094 0.591025Z" fill="black" />
        </svg>
    );
}