import React from 'react';
import { AppBar, Toolbar, Typography, Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';

const StyledAppBar = styled(AppBar)(({ theme }) => ({
  width: '100%',
  backgroundColor: '#ffebee',
  color: '#d32f2f',
  boxShadow: 'none',
  borderBottom: '1px solid #ffcdd2',
  position: 'fixed',
  borderTop: '1px solid #ffcdd2',
  bottom: 0,
  zIndex: 1001
}));

const StyledToolbar = styled(Toolbar)({
  justifyContent: 'center',
  minHeight: '30px !important'
});

const IconWrapper = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  marginRight: '8px',
});

export default function ApprovalAlert() {
  return (
    <StyledAppBar position="static" role="alert" aria-live="polite">
      <StyledToolbar>
        <IconWrapper>
          <ErrorOutlineIcon fontSize="small" />
        </IconWrapper>
        <Typography variant="body2" component="div" fontWeight="small">
          Ambiente de Homologação - Os dados e ações aqui não afetam o sistema de produção
        </Typography>
      </StyledToolbar>
    </StyledAppBar>
  );
}