import React, { useState, useEffect } from 'react';
import Slider from 'react-slick';
import { Box, Typography, Button, CardContent } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import './style-partners.css';
import { ReactComponent as YourSVG } from '../../../assets/images/nave.svg';
import { ReactComponent as DetailsSVG } from '../../../assets/images/detalhes.svg';
import api from "../../../services/api";
import truncateText from '../../../utils/truncateText';
import { Contact } from '../Contact'; // Import Contact component

interface CardProps {
  images: string[];
  title: string;
  subtitle: string;
  dimensions: string;
  suites: number;
  rooms: number;
  garages: number;
  city: string;
  state_abbreviation: string;
  neighborhood: string;
  secure_id: string;
}

const Card: React.FC<CardProps> = ({
  images,
  title,
  subtitle,
  city,
  state_abbreviation,
  neighborhood,
  dimensions,
  suites,
  rooms,
  garages,
  secure_id,
}) => {
  const navigate = useNavigate();

  const handleDetailsClick = () => {
    navigate(`/detalhes-do-imovel/${secure_id}`);
  };

  return (
    <Box className="card">
      {images.length > 1 ? (
        <Slider dots infinite speed={500} slidesToShow={1} slidesToScroll={1} arrows className="hide-dots">
          {images.map((img, index) => (
            <Box key={index}>
              <img src={img} alt={`Imagem ${index + 1}`} className="carouselImage" />
            </Box>
          ))}
        </Slider>
      ) : (
        <Box>
          <img
            src={images[0] || "/placeholder.svg?height=800&width=600"}
            alt="Imagem única"
            className="carouselImage"
            style={{ width: '100%', height: 'auto', objectFit: 'cover' }}
          />
        </Box>
      )}
      <Box className="cardContent">
        <Box className="svgIconWrapper">
          <YourSVG className="svgIcon" />
          <Typography className="iconText">{`${city} - ${state_abbreviation}`}</Typography>
        </Box>
        <Typography className="cardTitle">{title}</Typography>
        <Typography className="cardSubtitle">{subtitle}</Typography>
        <Box className="svgDetailsWrapper">
          <DetailsSVG className="detailsSvgIcon" />
        </Box>
        <Box className="detailssvg">
          <Box className="textPair">
            <Typography className="nunbsvg">{dimensions || '-'}</Typography>
            <Typography className="textsvg">m²</Typography>
          </Box>
          <Box className="textPair1">
            <Typography className="nunbsvg">{suites || '-'}</Typography>
            <Typography className="textsvg">{suites > 1 ? 'suítes' : 'suíte'}</Typography>
          </Box>
          <Box className="textPair">
            <Typography className="nunbsvg">{rooms || '-'}</Typography>
            <Typography className="textsvg">{rooms > 1 ? 'quartos' : 'quarto'}</Typography>
          </Box>
          <Box className="textPair">
            <Typography className="nunbsvg">{garages || '-'}</Typography>
            <Typography className="textsvg">{garages > 1 ? 'garagens' : 'garagem'}</Typography>
          </Box>
        </Box>
        <Box className="detailsButtonWrapper" style={{ width: '100%' }}>
          <Button className="detailsButton" onClick={handleDetailsClick}>
            Ver detalhes
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

const PrevArrow: React.FC<{ className?: string; onClick?: () => void }> = ({ className, onClick }) => (
  <div className={`${className} custom-carousel slick-prev_2`} onClick={onClick} />
);

const NextArrow: React.FC<{ className?: string; onClick?: () => void }> = ({ className, onClick }) => (
  <div className={`${className} custom-carousel slick-next_2`} onClick={onClick} />
);

const Partners: React.FC = () => {
  const [partnersData, setPartnersData] = useState<CardProps[]>([]);
  const [openDialog, setOpenDialog] = useState(false); // State to control modal open state

  useEffect(() => {
    const fetchPartnersData = async () => {
      try {
        const response = await api.get('list-sections/3');
        const partners = response.data.map((item: any) => ({
          images: item.images?.map((img: any) => img.path) || ["/placeholder.svg?height=800&width=600"],
          title: item.friendly_title,
          subtitle: `${item.property_type} - ${item.city}, ${item.state}.`,
          dimensions: item.area ? item.area.toString() : "N/A",
          neighborhood: item.neighborhood ? item.neighborhood.toString() : "",
          city: item.city ? item.city.toString() : "",
          state_abbreviation: item.state_abbreviation ? item.state_abbreviation.toString() : "",
          suites: item.suites || 0,
          rooms: item.bedrooms || 0,
          garages: item.parking_spots || 0,
          secure_id: item.secure_id || '',
        }));
        setPartnersData(partners);
      } catch (error) {
        console.error("Error fetching partners data:", error);
      }
    };

    fetchPartnersData();
  }, []);

  const settings = {
    dots: true,
    infinite: true,
    autoplay: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 3,
    arrows: true,
    prevArrow: <PrevArrow />,
    nextArrow: <NextArrow />,
    className: "custom-carousel",
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 375,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false,
        },
      },
    ],
  };

  return (
    <Box className="partners">

      <Box className="mainContainer">
        <CardContent sx={{ textAlign: 'center', borderBottom: 1, borderColor: 'transparent' }}>
          <Typography variant="h4" className="title-new">
            PARCEIROS
          </Typography>
          <Typography variant="subtitle1" className="subtitle">
            Procurando empreendimentos a pronta entrega? Nossos clientes e
            parceiros vão te apresentar bons negócios.
          </Typography>
        </CardContent>
        <Slider {...settings}>
          {partnersData.map((partner: any, index) => (
            <div key={index} className="custom-slide">
              <Card
                images={partner.images}
                title={truncateText(partner.title || '', 25)}
                subtitle={truncateText(partner.subtitle || '', 100)}
                city={partner.city}
                state_abbreviation={partner.state_abbreviation}
                neighborhood={partner.neighborhood}
                dimensions={partner.dimensions}
                suites={partner.suites}
                rooms={partner.rooms}
                garages={partner.garages}
                secure_id={partner.secure_id}
              />
            </div>
          ))}
        </Slider>
        <Button className="mainButton" onClick={() => setOpenDialog(true)}>
          Saiba mais
        </Button>
        <Contact openDialog={openDialog} onCloseDialog={() => setOpenDialog(false)} />
      </Box>
    </Box>
  );
};

export default Partners;