


// Discover.tsx
import React, { useEffect, useState } from 'react';
import { Box, Button, Typography } from '@mui/material';
import Slider from 'react-slick';
import api from '../../../services/api';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import './style.css';
import { Contact } from '../Contact';

interface Image { id: number; path: string }

const settings = {
  infinite: true, autoplay: true, autoplaySpeed: 0, speed: 3000, slidesToShow: 3, slidesToScroll: 1,
  centerPadding: '10%', initialSlide: 0, cssEase: 'ease',
  responsive: [
    { breakpoint: 500, settings: { slidesToShow: 1, slidesToScroll: 1, centerPadding: '0%' } },
    { breakpoint: 768, settings: { slidesToShow: 2, slidesToScroll: 2, centerPadding: '5%' } },
    { breakpoint: 480, settings: { slidesToShow: 1.02, slidesToScroll: 1, centerPadding: '0%' } },
  ]
};

export function Discover() {
  const [images, setImages] = useState<Image[]>([]);
  const [openDialog, setOpenDialog] = useState(false); // Estado para abrir o Contact

  useEffect(() => {
    api.get('/vext-universe').then(res => setImages(res.data)).catch(() => console.error('Erro ao buscar imagens'));
  }, []);

  return (
    <>
      <Box className="svg-container" sx={{ backgroundColor: '#C7FD06' }}>
        <Box className="content-img">
          <div className="light-effect" />
          <img src={`${process.env.PUBLIC_URL}/assets/images/any/diagonal-arrows.png`} alt="Setas diagonais" />
        </Box>
        <Box className="banner-container">
          <Typography className='text-banner' variant="h4" sx={{ mb: 2 }}>
            descubra o <br /> universo vext!
            <Button className="banner-button" variant="contained" onClick={() => setOpenDialog(true)}>Seguir</Button>
          </Typography>
          <Box className="custom-carousel-discover" sx={{ width: { xs: '100%', md: '70%' } }}>
            <Slider {...settings}>
              {images.map((image) => (
                <Box key={image.id} sx={{ padding: '5px' }}>
                  <Box component="img" src={image.path} sx={{ width: '100%', height: { xs: '300px', md: '40vh' }, borderRadius: '10px' }} />
                </Box>
              ))}
            </Slider>
          </Box>
        </Box>
      </Box>

      {/* Usando o componente Contact como diálogo */}
      <Contact openDialog={openDialog} onCloseDialog={() => setOpenDialog(false)} />
    </>
  );
}