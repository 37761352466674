'use client'

import React, { useEffect, useRef } from 'react';
import { Box } from '@mui/material';

export default function SvgContainer() {
    const boxRef = useRef<HTMLDivElement>(null);
    const lightCircleRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        const handleMouseMove = (event: MouseEvent) => {
            if (boxRef.current && lightCircleRef.current) {
                // Calculando a posição relativa do mouse na tela
                const { left, top } = boxRef.current.getBoundingClientRect();
                const x = event.clientX - left - 75;  // Ajuste do centro do círculo
                const y = event.clientY - top - 150; // Ajuste do centro do círculo

                // Atualizando a posição do círculo de luz
                requestAnimationFrame(() => {
                    lightCircleRef.current!.style.transform = `translate(${x}px, ${y}px)`;
                });
            }
        };

        // Adicionando o evento de movimento do mouse no documento global
        document.addEventListener('mousemove', handleMouseMove);

        return () => {
            // Remover o evento de movimento do mouse ao desmontar o componente
            document.removeEventListener('mousemove', handleMouseMove);
        };
    }, []);

    return (
        <Box
            ref={boxRef}
            className="svgContainer"
            sx={{
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
                background: '#fff',
                overflow: 'hidden',
                zIndex: 1
            }}
        >
            <img
                src={`${process.env.PUBLIC_URL}/assets/images/banner/bg-hexagon.png`}
                alt="Hexagono 1"
                style={{
                    position: 'absolute',
                    height: '100%',
                    width: 'auto',
                    objectFit: 'cover',
                    left: 0,
                    zIndex: 1,
                }}
            />
            <img
                src={`${process.env.PUBLIC_URL}/assets/images/banner/bg-hexagon-right.png`}
                alt="Hexagono 2"
                style={{
                    position: 'absolute',
                    height: '100%',
                    width: 'auto',
                    objectFit: 'cover',
                    right: 0,
                    zIndex: 1,
                }}
            />

            {/* Círculo de luz que segue o mouse */}
            <div
                ref={lightCircleRef}
                style={{
                    width: '150px',
                    height: '150px',
                    borderRadius: '50%',
                    position: 'absolute',
                    pointerEvents: 'none',
                    background: 'rgba(199, 253, 6)',
                    boxShadow: `
                            0 0 30px rgba(199, 253, 6),
                            0 0 50px rgba(199, 253, 6),
                            0 0 70px rgba(199, 253, 6),
                            0 0 90px rgba(199, 253, 6)
                        `,
                    transform: 'translate(-75px, -75px)', // Start at the center of the element
                    zIndex: 0,
                }}
            />
        </Box>
    );
}