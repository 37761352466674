import React, { useState, useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";
import NavBar from "../../components/NavBar";
import { ThemeProvider } from "@mui/material";
import monochrome from "../../Themes/Monochrome";
import { Header } from "../../sections/Home/Header";
import { Matchmaker } from "../../sections/Home/Matchmaker";
import Testimonials from "../../sections/Home/Testimonials";
import CompanyLocations from "../../sections/Home/CompanyLocations";
import Footer from "../../sections/Home/Footer";
import Carousel from "../../sections/Home/Carousel";
import api from "../../services/api";
import Partners from "../../sections/Home/Partners";
import { Discover } from "../../sections/Home/Discover/index";
import { Contact } from "../../sections/Home/Contact";

const Home = () => {
  const [carouselData, setCarouselData] = useState([]);
  const location = useLocation();

  // Estado para controlar a abertura do diálogo de contato
  const [openContactDialog, setOpenContactDialog] = useState(false);

  // Função para abrir o diálogo de contato
  const handleOpenContactDialog = () => setOpenContactDialog(true);
  const handleCloseContactDialog = () => setOpenContactDialog(false);

  const headerRef = useRef<HTMLDivElement>(null);
  const matchmakerRef = useRef<HTMLDivElement>(null);
  const testimonialsRef = useRef<HTMLDivElement>(null);
  const partnersRef = useRef<HTMLDivElement>(null);
  const discoverRef = useRef<HTMLDivElement>(null);
  const carouselRefs = useRef<Array<HTMLDivElement | null>>([]);

  const refs:any = {
    header: headerRef,
    matchmaker: matchmakerRef,
    testimonials: testimonialsRef,
    partners: partnersRef,
    discover: discoverRef,
    carousel: carouselRefs,
  };

  useEffect(() => {
    const scrollToSection = location.state?.scrollTo;

    if (scrollToSection) {
      if (scrollToSection === "carousel" && carouselRefs.current[0] instanceof HTMLElement) {
        carouselRefs.current[0].scrollIntoView({ behavior: "smooth" });
      } else if (refs[scrollToSection]?.current instanceof HTMLElement) {
        refs[scrollToSection].current.scrollIntoView({ behavior: "smooth" });
      }
    }
  }, [location, refs]);

  useEffect(() => {
    const fetchCarousels = async () => {
      try {
        const response = await api.get("list-sections");
        const allCarousels = response.data;
        const filteredCarousels = allCarousels.filter((carousel: any) => carousel.id !== 3);
        setCarouselData(filteredCarousels);
      } catch (error) {
        console.error("Erro ao buscar dados dos carrosséis:", error);
      }
    };
    fetchCarousels();
  }, []);

  return (
    <ThemeProvider theme={monochrome}>
      <NavBar refs={refs} openContactDialog={handleOpenContactDialog} />
      <div ref={headerRef}><Header /></div>
      <div ref={matchmakerRef}><Matchmaker /></div>
      {carouselData.map((carousel: any, index) => (
        <div key={carousel.id} ref={(el) => (carouselRefs.current[index] = el)}>
          <Carousel title={carousel.name} subTitle={carousel.friendly_title} id={carousel.id} />
        </div>
      ))}
      <div ref={partnersRef}><Partners /></div>
      <div ref={discoverRef}><Discover /></div>
      <div ref={testimonialsRef}><Testimonials /></div>
      <CompanyLocations />
      <Footer />

      {/* Renderiza o diálogo Contact */}
      <Contact openDialog={openContactDialog} onCloseDialog={handleCloseContactDialog} />
    </ThemeProvider>
  );
};

export default Home;