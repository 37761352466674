import { Box, Button, Container, Typography } from '@mui/material';
import { motion } from 'framer-motion';
import './style.css';

export function Header() {
    const textVariants = {
        hidden: { opacity: 0, y: 80 },
        visible: { opacity: 1, y: 0, transition: { type: 'spring', stiffness: 100 } },
    };

    const buttonVariants = {
        hidden: { opacity: 0, y: 80 },
        visible: { opacity: 1, y: 0, transition: { type: 'spring', stiffness: 100 } },
    };

    const imageVariants = {
        hidden: { opacity: 0, x: 100, y: '-600px' },
        visible: { opacity: 1, x: 0, y: '-600px', transition: { type: 'spring', stiffness: 70, delay: 0.5 } },
    };

    return (
        <Box className="outer-box">
            <Box className="background"
                sx={{
                    backgroundImage: {
                        xs: `url(${process.env.PUBLIC_URL}/assets/images/banner/familia.jpg)`, // Mobile background
                        sm: `url(${process.env.PUBLIC_URL}/assets/images/banner/bg-astro.jpg)`, // Desktop background
                    },
                    backgroundPositionX:{
                        xs: `40%`,
                        sm: `center`
                    }
                }}>
                <Container maxWidth="lg" className="container">
                    <Box className="box boxWidth-xs textAlign-xs">
                        <motion.div initial="hidden" animate="visible" variants={textVariants}>
                            <Typography variant="h2" component="h1" gutterBottom className="title titleSize-xs" color="white">
                                <span className="highlight">Bem-vindo à Vext:</span> Conectamos você aos
                                melhores negócios e cultivamos sucessos com
                                assessoria especializada
                            </Typography>
                        </motion.div>
                        <motion.div initial="hidden" animate="visible" variants={buttonVariants}>
                            <Button
                                variant="contained"
                                className="actionButton"
                                onClick={() => window.open('https://wa.me/+5547991683239?text=Olá! Gostaria de mais informações.', '_blank')}
                            >
                                Falar com um Vexter
                            </Button>
                        </motion.div>
                    </Box>

                    {/* Box para a imagem flutuante */}
                    <motion.div initial="hidden" animate="visible" variants={imageVariants}>
                        <Box className="floating-image">
                            <img src={`${process.env.PUBLIC_URL}/assets/images/banner/astronaut.png`} alt="Imagem Flutuante" />
                        </Box>
                    </motion.div>
                </Container>
            </Box>
        </Box>
    );
}